<template>
  <div class="certificate-link">
    <a :href="base64"  download="certificate.png">
      {{$t('label.downloadCertificate')}}<span><DownloadIcon /></span></a>
    <lera-certificate-generator
      v-show="false"
      :value="certificate"
      @canvas-updated="onCanvasUpdate"
    />
  </div>
</template>

<script>
import DownloadIcon from '@/components/Icons/DownloadIcon.vue';

export default {
  components: { DownloadIcon },
  props: {
    certificate: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    base64: '',
  }),

  methods: {
    onCanvasUpdate(base64String) {
      this.base64 = base64String;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
