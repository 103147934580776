<template>
  <!-- eslint-disable max-len -->
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 7.33594H3.33333C2.59695 7.33594 2 7.93289 2 8.66927V13.3359C2 14.0723 2.59695 14.6693 3.33333 14.6693H12.6667C13.403 14.6693 14 14.0723 14 13.3359V8.66927C14 7.93289 13.403 7.33594 12.6667 7.33594Z"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66602 7.33594V4.66927C4.66602 3.78522 5.01721 2.93737 5.64233 2.31225C6.26745 1.68713 7.11529 1.33594 7.99935 1.33594C8.8834 1.33594 9.73125 1.68713 10.3564 2.31225C10.9815 2.93737 11.3327 3.78522 11.3327 4.66927V7.33594"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockIcon',
};
</script>
