<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="9" rx="2" fill="#D9D9D9"/>
    <rect y="12" width="16" height="3" rx="1.5" fill="#D9D9D9"/>
  </svg>
</template>

<script>
export default {
  name: 'PileListIcon',
};
</script>
