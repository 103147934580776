<template>
  <!-- eslint-disable max-len -->
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895432 0 2V3.80582C0 4.91039 0.895432 5.80582 2 5.80582H4.18453C5.2891 5.80582 6.18453 4.91039 6.18453 3.80582V2C6.18453 0.89543 5.2891 0 4.18453 0H2ZM11.8125 0C10.7079 0 9.8125 0.895432 9.8125 2V3.80582C9.8125 4.91039 10.7079 5.80582 11.8125 5.80582H13.997C15.1016 5.80582 15.997 4.91039 15.997 3.80582V2C15.997 0.89543 15.1016 0 13.997 0H11.8125ZM9.8125 11.2891C9.8125 10.1845 10.7079 9.28906 11.8125 9.28906H13.997C15.1016 9.28906 15.997 10.1845 15.997 11.2891V13.0949C15.997 14.1995 15.1016 15.0949 13.997 15.0949H11.8125C10.7079 15.0949 9.8125 14.1995 9.8125 13.0949V11.2891ZM2 9.28906C0.895431 9.28906 0 10.1845 0 11.2891V13.0949C0 14.1995 0.895432 15.0949 2 15.0949H4.18453C5.2891 15.0949 6.18453 14.1995 6.18453 13.0949V11.2891C6.18453 10.1845 5.2891 9.28906 4.18453 9.28906H2Z" fill="#F2F2F2"/>
  </svg>
</template>

<script>
export default {
  name: 'CardListIcon',
};
</script>
